<script setup>

</script>

<template>
  <div class="flex justify-center items-center gap-1 py-2 z-10 font-extralight text-xs rounded-br">
    <div>Powered by</div>
    <div>
      <VectorVehisHorizontal class="w-14 inline-block" />
    </div>
  </div>
</template>
